/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import headerImg from "@static/images/shop/idahobit-shop_header.jpeg"
import ShopContainer from "@shop/modules/ShopContainer"

const PrideShop = ({ data, ...props }) => {
  return (
    <ShopContainer
      pageContent={{
        heading: "Shop Pride Merchandise",
        image: headerImg,
        pillHeading: "What are you looking for?",
        pageTitle: "Shop LGBTQIA+ Pride",
        headerText:
          "Flags, rainbow ribbons, posters, lanyards, pronoun pins and pride packs – we've made it easy to bring your IDAHOBIT activity to life.",
        pageDescription: data.og.frontmatter.ogdescription,
      }}
      data={data}
      pathname={props.location.pathname}
      location={props.location}
      headerBg="purple"
      headerColor="white"
    />
  )
}
export default PrideShop

export const pageQuery = graphql`
  query {
    pride: shopifyCollection(handle: { eq: "pride" }) {
      ...ShopifyCollectionFragment
    }
    school: shopifyCollection(handle: { eq: "schools" }) {
      ...ShopifyCollectionFragment
    }
    workplace: shopifyCollection(handle: { eq: "workplace" }) {
      ...ShopifyCollectionFragment
    }
    og: markdownRemark(fileAbsolutePath: { glob: "**/pages/shop/index.md" }) {
      frontmatter {
        ogdescription
        accordion {
          ...Accordion
        }
        footerImg
      }
    }
  }
`
